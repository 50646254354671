import { useState, useEffect } from 'react';

export default function useInput(initialValue) {
  const [value, setValue] = useState(initialValue);

  const onChange = (e) => {
    if (e?.target?.value) {
      setValue(e.target.value);
    } else if (e?.target?.value?.length == 0) {
      setValue('');
    } else {
      setValue(e);
    }
  };

  useEffect(() => {
    if (initialValue?.length) {
      setValue(initialValue);
    } else {
      setValue('');
    }
  }, [initialValue]);

  return {
    value,
    onChange,
  };
}

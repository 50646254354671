import { configureStore } from '@reduxjs/toolkit';
import userSlice from './reducers/userSlice';
import companySlice from './reducers/companySlice';
import storeSlice from './reducers/storeSlice';
import productSlice from './reducers/productSlice';
import productStoreSlice from './reducers/productStoreSlice';

export const store = configureStore({
  reducer: {
    user: userSlice,
    company: companySlice,
    store: storeSlice,
    product: productSlice,
    productStore: productStoreSlice,
  },
});

import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Home from '../Pages/Home';
import ReadToken from '../Components/ReadToken';
import Callback from '../callback';
import Refresh from '../refresh';

const PageNotFound = () => <div>Page not found123</div>;

const Routes = () => (
  <Switch>
    <Route exact path="/" component={Home} />
    <Route path="/callback.html" component={Callback} />
    <Route exact path="/updatetoken.html" component={Refresh} />
    <Route exact path="/app" component={ReadToken} />
    <Route component={PageNotFound} />
  </Switch>
);

export default Routes;

import React, { useState, useEffect } from 'react';
import Network from './Requests';
import { useSelector, useDispatch } from 'react-redux';
import { setUser } from '../store/reducers/userSlice';
import { setListCompany, setCurrent } from '../store/reducers/companySlice';
import { setStore, setCurrentStore } from '../store/reducers/storeSlice';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

export default function Store(props) {
  const dispatch = useDispatch();

  const user = useSelector((state) => state.user.current);
  const companyList = useSelector((state) => state.company.companies);
  const companyCurrent = useSelector((state) => state.company.current);
  const store = useSelector((state) => state.store.list);
  const storeCurrent = useSelector((state) => state.store.current);

  const changeCompany = (e) => {
    dispatch(setCurrent(e.target.value));
    localStorage.setItem('company', JSON.stringify({ id: e.target.value }));
  };

  const changeStore = (id, guid) => {
    dispatch(setCurrentStore({ id: id, guid: guid }));
  };

  useEffect(() => {
    const fetch = async () => {
      if (companyCurrent) {
        const store = await new Network().GetStoresByCompany(companyCurrent);

        dispatch(setStore(store));
        dispatch(setCurrentStore({ id: store[0]?.id, guid: store[0]?.guid }));
      } else {
        dispatch(setStore([]));
        dispatch(setCurrentStore({ id: '', guid: '' }));
      }
    };

    fetch();
  }, [companyCurrent]);

  useEffect(() => {
    const fetch = async () => {
      const userInfo = await new Network().GetUsersInfo();
      const company = await new Network().GetUserCompanies();
      const companyCurrentServ = company.find((c) => c.isDefaultCompany == true);
      const companyLocal = JSON.parse(localStorage.getItem('company'))?.id;

      let insertCompany;

      if (companyLocal) {
        insertCompany = companyLocal;
      } else {
        insertCompany = companyCurrentServ?.id;
      }

      const store = await new Network().GetStoresByCompany(insertCompany);

      dispatch(setStore(store));
      dispatch(setCurrentStore({ id: store[0]?.id, guid: store[0]?.guid }));
      dispatch(setUser(userInfo));
      dispatch(setListCompany(company));
      dispatch(setCurrent(insertCompany));
    };

    fetch();
  }, []);

  return (
    <div className="wrap storeBlock">
      <div className="user">
        <img src={user?.profilePictureFilePath} />
        {user?.name} {user?.lastName}
      </div>
      <div className="company-select">
        <FormControl>
          <InputLabel htmlFor="age-native-simple">Компания</InputLabel>
          {companyCurrent && (
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={companyCurrent}
              onChange={changeCompany}
              label="Компания">
              {companyList?.map((c) => {
                return (
                  <MenuItem key={c.id} value={c.id}>
                    {c.name}
                  </MenuItem>
                );
              })}
            </Select>
          )}
        </FormControl>
      </div>
      <div className="store">
        {store
          ? store?.map((c) => {
              return (
                <div
                  className={'store--name ' + (c.guid == storeCurrent.guid ? 'active' : '')}
                  onClick={(e) => changeStore(c.id, c.guid)}
                  key={c.guid}>
                  {c.domain}
                </div>
              );
            })
          : 'Доменов нет'}
      </div>
    </div>
  );
}

import React, { useState, useEffect } from 'react';
import Network from './Requests';
import { useSelector, useDispatch } from 'react-redux';
import { setProductStore, setOpenStore } from '../store/reducers/productStoreSlice';
import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';
import Box from '@material-ui/core/Box';

export default function ModalStore(props) {
  const dispatch = useDispatch();
  const productCurrent = useSelector((state) => state.product.current);
  const storeState = useSelector((state) => state.productStore.open);
  const storeList = useSelector((state) => state.productStore.list);

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    overflowX: 'hidden',
    overflowY: 'scroll',
    height: '95%',
  };

  const store = [
    {
      name: 'studio_small_08_1k.hdr',
      url: 'https://storage.yandexcloud.net/system123.render.lab/calculationResults/464c2ccc-e2e3-4afd-935f-51a411dd8541/studio_small_08_1k.hdr',
    },
    {
      name: 'thatch_chapel_1k_desaturate.hdr',
      url: 'https://storage.yandexcloud.net/system123.render.lab/calculationResults/464c2ccc-e2e3-4afd-935f-51a411dd8541/thatch_chapel_1k_desaturate.hdr',
    },
    {
      name: 'solitude_interior_4k.hdr',
      url: 'https://storage.yandexcloud.net/system123.render.lab/calculationResults/464c2ccc-e2e3-4afd-935f-51a411dd8541/solitude_interior_4k.hdr',
    },
    {
      name: 'solitude_interior_1k.hdr',
      url: 'https://storage.yandexcloud.net/system123.render.lab/calculationResults/464c2ccc-e2e3-4afd-935f-51a411dd8541/solitude_interior_1k.hdr',
    },
    {
      name: 'dancing_hall_1k.hdr',
      url: 'https://storage.yandexcloud.net/system123.render.lab/calculationResults/464c2ccc-e2e3-4afd-935f-51a411dd8541/dancing_hall_1k.hdr',
    },
    {
      name: 'pine_attic_1k.hdr',
      url: 'https://storage.yandexcloud.net/system123.render.lab/calculationResults/464c2ccc-e2e3-4afd-935f-51a411dd8541/pine_attic_1k.hdr',
    },
    {
      name: 'evening_road_01_puresky_1k.hdr',
      url: 'https://storage.yandexcloud.net/system123.render.lab/calculationResults/464c2ccc-e2e3-4afd-935f-51a411dd8541/evening_road_01_puresky_1k.hdr',
    },
    {
      name: 'yoga_room_1k.hdr',
      url: 'https://storage.yandexcloud.net/system123.render.lab/calculationResults/464c2ccc-e2e3-4afd-935f-51a411dd8541/yoga_room_1k.hdr',
    },
    {
      name: 'sunflowers_puresky_1k.hdr',
      url: 'https://storage.yandexcloud.net/system123.render.lab/calculationResults/464c2ccc-e2e3-4afd-935f-51a411dd8541/sunflowers_puresky_1k.hdr',
    },
    {
      name: 'table_mountain_2_puresky_1k.hdr',
      url: 'https://storage.yandexcloud.net/system123.render.lab/calculationResults/464c2ccc-e2e3-4afd-935f-51a411dd8541/table_mountain_2_puresky_1k.hdr',
    },
    {
      name: 'kloofendal_43d_clear_1k.hdr',
      url: 'https://storage.yandexcloud.net/system123.render.lab/calculationResults/464c2ccc-e2e3-4afd-935f-51a411dd8541/kloofendal_43d_clear_1k.hdr',
    },
    {
      name: 'brown_photostudio_03_2k.hdr',
      url: 'https://storage.yandexcloud.net/system123.render.lab/calculationResults/464c2ccc-e2e3-4afd-935f-51a411dd8541/brown_photostudio_03_2k.hdr',
    },
    {
      name: 'chinese_garden_2k.hdr',
      url: 'https://storage.yandexcloud.net/system123.render.lab/calculationResults/464c2ccc-e2e3-4afd-935f-51a411dd8541/chinese_garden_2k.hdr',
    },
    {
      name: 'little_paris_eiffel_tower_1k.hdr',
      url: 'https://storage.yandexcloud.net/system123.render.lab/calculationResults/464c2ccc-e2e3-4afd-935f-51a411dd8541/little_paris_eiffel_tower_1k.hdr',
    },
    {
      name: 'old_apartments_walkway_1k.hdr',
      url: 'https://storage.yandexcloud.net/system123.render.lab/calculationResults/464c2ccc-e2e3-4afd-935f-51a411dd8541/old_apartments_walkway_1k.hdr',
    },
    {
      name: 'thatch_chapel_1k.hdr',
      url: 'https://storage.yandexcloud.net/system123.render.lab/calculationResults/464c2ccc-e2e3-4afd-935f-51a411dd8541/thatch_chapel_1k.hdr',
    },
    {
      name: 'wide_street_02_1k.hdr',
      url: 'https://storage.yandexcloud.net/system123.render.lab/calculationResults/464c2ccc-e2e3-4afd-935f-51a411dd8541/wide_street_02_1k.hdr',
    },
    {
      name: 'empty_warehouse_01_1k.hdr',
      url: 'https://storage.yandexcloud.net/system123.render.lab/calculationResults/464c2ccc-e2e3-4afd-935f-51a411dd8541/empty_warehouse_01_1k.hdr',
    },
    {
      name: 'derelict_overpass_1k.hdr',
      url: 'https://storage.yandexcloud.net/system123.render.lab/calculationResults/464c2ccc-e2e3-4afd-935f-51a411dd8541/derelict_overpass_1k.hdr',
    },
    {
      name: 'brown_photostudio_02_1k.hdr',
      url: 'https://storage.yandexcloud.net/system123.render.lab/calculationResults/464c2ccc-e2e3-4afd-935f-51a411dd8541/brown_photostudio_02_1k.hdr',
    },
  ];

  const handleClose = () => {
    dispatch(setOpenStore(false));
  };

  useEffect(() => {
    dispatch(setProductStore(store));
  }, []);

  const setPath = (e) => {
    props.setInput(e);
    dispatch(setOpenStore(false));
  };

  return (
    <Modal
      open={storeState}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <div className="storeProduct-container">
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h4" component="h2">
            Готовые расчёты
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            {store &&
              store?.map((c) => {
                return (
                  <div className={'storeProcust--item'} onClick={() => setPath(c.url)}>
                    {c.name}
                  </div>
                );
              })}
          </Typography>
        </Box>
      </div>
    </Modal>
  );
}

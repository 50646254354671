import React from 'react';
import Store from '../../Components/Store';
import Product from '../../Components/Product';
import ProductInfo from '../../Components/ProductInfo';

function Main() {
  const greeting = 'Hello Function Component!';

  return (
    <div className="wrapper-main">
      <div>
        <Store />
      </div>
      <div>
        <Product />
      </div>
      <div>
        <ProductInfo />
      </div>
    </div>
  );
}

export default Main;

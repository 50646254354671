import { createSlice, createAsyncThunk, current } from '@reduxjs/toolkit';
import Network from '../../Components/Requests';

const initialState = {
  list: [],
  open: false,
};

export const productStoreSlice = createSlice({
  name: 'productStore',
  initialState,
  reducers: {
    setProductStore: (state, action) => {
      state.list = action.payload;
    },
    setOpenStore: (state, action) => {
      state.open = action.payload;
    },
  },
});

export const { setProductStore, setOpenStore } = productStoreSlice.actions;

export default productStoreSlice.reducer;

import { createSlice, createAsyncThunk, current } from '@reduxjs/toolkit';
import Network from '../../Components/Requests';

const initialState = {
  list: [],
  current: '',
  info: {},
  type: '',
  save: false,
};

export const productSlice = createSlice({
  name: 'product',
  initialState,
  reducers: {
    setListProduct: (state, action) => {
      state.list = action.payload;
    },
    setCurrentProduct: (state, action) => {
      state.current = action.payload;
    },
    setInfoProduct: (state, action) => {
      state.info = action.payload;
    },
    setType: (state, action) => {
      state.type = action.payload;
    },
    setSave: (state, action) => {
      state.save = action.payload;
    },
    clearList: (state, action) => {
      state.list = [];
      state.current = '';
      state.info = {};
    },
  },
});

export const {
  setListProduct,
  setCurrentProduct,
  setInfoProduct,
  clearList,
  skyboxPath,
  skyboxPathIos,
  setType,
  setSave,
} = productSlice.actions;

export default productSlice.reducer;

import { createSlice, createAsyncThunk, current } from '@reduxjs/toolkit';
import Network from '../../Components/Requests';

const initialState = {
  list: [],
  current: { id: '', guid: '' },
};

export const storeSlice = createSlice({
  name: 'store',
  initialState,
  reducers: {
    setStore: (state, action) => {
      state.list = action.payload;
    },
    setCurrentStore: (state, action) => {
      state.current = action.payload;
    },
  },
});

export const { setStore, setCurrentStore } = storeSlice.actions;

export default storeSlice.reducer;

import React, { useState, useEffect } from 'react';
import Network from './Requests';
import { useSelector, useDispatch } from 'react-redux';
import {
  setListProduct,
  setCurrentProduct,
  clearList,
  setSave,
} from '../store/reducers/productSlice';

export default function Product(props) {
  const dispatch = useDispatch();
  const storeCurrent = useSelector((state) => state.store.current);
  const productList = useSelector((state) => state.product.list);
  const productCurrent = useSelector((state) => state.product.current);

  const changeCurrentProduct = (token) => {
    dispatch(setSave(false));
    dispatch(setCurrentProduct(token));
  };

  const clear = () => {
    const fetch = async () => {
      const product = await new Network().GetByStore(storeCurrent?.id);

      if (product?.length > 0) {
        dispatch(clearList());
        dispatch(setSave(true));
        dispatch(setListProduct(product));
      }
    };
    fetch();
  };

  useEffect(() => {
    const fetch = async () => {
      const product = await new Network().GetByStore(storeCurrent?.id);

      if (product?.length > 0) {
        dispatch(setListProduct(product));
        dispatch(setCurrentProduct(product[0]?.token));
      } else {
        dispatch(clearList());
      }
    };
    fetch();
  }, [storeCurrent]);

  return (
    <div className="wrap productBlock">
      <div className="product">
        <div className="product--name">Товары</div>
        <div className="product--add" onClick={clear}>
          <svg
            className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiBox-root css-1om0hkc"
            focusable="false"
            aria-hidden="true"
            viewBox="0 0 24 24"
            data-testid="ControlPointIcon">
            <path d="M13 7h-2v4H7v2h4v4h2v-4h4v-2h-4V7zm-1-5C6.49 2 2 6.49 2 12s4.49 10 10 10 10-4.49 10-10S17.51 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"></path>
          </svg>
        </div>
      </div>
      <div className="product-list">
        {productList
          ? productList.map((p) => {
              return (
                <div
                  key={p?.token}
                  className={'product-item ' + (p.token == productCurrent ? 'active' : '')}
                  onClick={(e) => changeCurrentProduct(p.token)}>
                  <div
                    className="product-img"
                    style={{
                      backgroundImage: `url("${p.iconPath !== null && p?.iconPath}`,
                    }}></div>
                  {p?.name}
                </div>
              );
            })
          : 'Товаров нет'}
      </div>
    </div>
  );
}

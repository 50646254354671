import React, { useState, useEffect } from 'react';
import Main from '../Pages/Main';
import AuthService from '../Services/AuthService';
import Network from '../Components/Requests';

const ReadToken = () => {
  const [authService, setAuthService] = useState(new AuthService());
  const [user, setUser] = useState(null);
  const [userGuid, setUserGuid] = useState(null);

  useEffect(() => {
    const getUser = async () => {
      const data = await authService.getUser();
      if (!data) {
        window.location.href = '/';
        return;
      }
      let checkCall;

      if (userGuid == null) {
        checkCall = await new Network().GetUserGuid();
      }

      setUserGuid(checkCall);
      if (!checkCall) {
        if (data.refresh_token) {
          try {
            var renewTokenCall = await authService.renewToken();
          } catch (err) {
            authService.logout();
            return;
          }
          if (!renewTokenCall) {
            authService.logout();
            return;
          }
          setUser(data);
        } else {
          authService.logout();
        }
        return;
      }
      setUser(data);

      window.addEventListener('message', function (e) {
        if (e.data.type == 'request_token') {
          var message = {
            type: 'token',
            value: data.access_token,
          };
          var iFrame = document.getElementById('view-container-iframe');

          if (iFrame != null) {
            iFrame.contentWindow.postMessage(message, '*');
          }
        }
      });
    };

    localStorage.removeItem('types');
    getUser();
  }, []);

  return <div>{user && <Main user={userGuid} />}</div>;
};

export default ReadToken;
